import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import { partners, forUsPages } from '../../data/data';
import loadable from '@loadable/component';

import '../../styles/partners.scss';

const Grid = loadable(() => import('../../components/grid'));
const SubMenu = loadable(() => import('../../components/subMenu'));
const Content = loadable(() => import('../../components/content'));

const Partners = ({ data, location }) => {
  const letters = data.letters.edges.map(item => item.node.childImageSharp.fluid);
  const profiles = data.profiles.edges.map(item => item.node.childImageSharp.fluid);

  const metaData = {
    title: 'Партньори'
  };

  return (
    <Layout metaData={metaData}>
      <SubMenu location={location.pathname} sectionItems={forUsPages} />
      <div className="partners">
        <Grid>
          {partners.map((partner, index) => (
            <section key={`key${index}`} className="partner">
              <header>
                <div className="partner-letters">
                  <Img loading="eager" fadeIn={false} fluid={letters[index * 2]} />
                  <Img loading="eager" fadeIn={false} fluid={letters[index * 2 + 1]} />
                </div>
                <Img loading="eager" fadeIn={false} fluid={profiles[index]} />
              </header>
              <Content>
                <h1>{partner.name}</h1>
                <ul>
                  {partner.list.map((bullet, i) => (
                    <li key={i}>{bullet}</li>
                  ))}
                </ul>
              </Content>
            </section>
          ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default Partners;

export const query = graphql`
  query partners {
    letters: allFile(sort: { fields: name }, filter: { relativePath: { regex: "/images/personal/letters/" } }) {
      edges {
        node {
          id
          ...mediumImage
        }
      }
    }
    profiles: allFile(sort: { fields: name }, filter: { relativePath: { regex: "/images/personal/profiles/" } }) {
      edges {
        node {
          id
          ...mediumImage
        }
      }
    }
  }
`;
